import React, { useEffect, useState } from 'react';
import { Button, Form, notification } from 'antd';
import { documentEditStore } from './documentEditStore';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageContainer } from 'components/PageContainer';
import DocumentForm from '../common/form/DocumentForm';
import { IDocumentInfo } from '../types';
import api from 'api';
import SignModal, { signDocument } from 'components/SignModal';
import { Certificate } from 'crypto-pro';
import { formatISO } from 'date-fns';

export interface IFormValues {
    date: Date;
    documentNumber: string;
    recipients: { label: string; value: string }[];
    documentType: string;
}

const DocumentEdit = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [submittable, setSubmittable] = useState<boolean>(false);
    const { draftId } = useParams<{ draftId: string }>();
    const navigate = useNavigate();
    const { documentResponse } = documentEditStore;
    const [form] = Form.useForm();

    useEffect(() => {
        if (draftId && !documentResponse) {
            documentEditStore.init(draftId);
        }

        return () => {
            documentEditStore.reset();
        };
    }, [draftId]);

    const handleSubmit = (values: IFormValues, successCallback?: () => void, errorCallback?: (err: any) => void) => {
        const recipients = values.recipients.map(({ value }) => ({
            inn: value,
        }));
        const result: IDocumentInfo = {
            documentNumber: values.documentNumber,
            documentType: values.documentType,
            date: formatISO(values.date),
            recipients,
        };
        api.put('/api/v1/documents', {
            draftFeedId: draftId,
            ...result,
        })
            .then((res) => {
                if (res.status === 200) {
                    successCallback && successCallback();
                }
            })
            .catch((err) => {
                errorCallback && errorCallback(err);
            });
    };

    const handleSendDocument = () => {
        api.post('/api/v1/documents/actions', {
            documentFeedId: documentResponse?.id,
            action: 'send',
        })
            .then((res) => {
                if (res.status === 200) {
                    navigate('/outbox');
                    notification.open({
                        type: 'success',
                        message: 'Успешно',
                        description: 'Документ успешно отправлен',
                    });
                }
            })
            .catch((err) => {
                notification.open({ type: 'error', message: 'Ошибка', description: err.message });
            });
    };

    const handleSignDocument = async (certificate: Certificate | null) => {
        if (!certificate) {
            return;
        }
        let signatureBase64 = '';

        const fileResponse = await api.get<Blob>('/api/v1/files?fileId=' + documentResponse?.document?.file?.[0]?.id, {
            responseType: 'blob',
        });

        if (fileResponse.status !== 200) {
            notification.open({ type: 'error', message: 'Ошибка', description: 'Не удалось загрузить файл' });

            return;
        }

        try {
            signatureBase64 = await signDocument(fileResponse.data, certificate);
        } catch (err: any) {
            console.error(err);
            notification.open({ type: 'error', message: 'Ошибка', description: err.message });

            return;
        }

        api.post('/api/v1/documents/actions', {
            documentFeedId: documentResponse?.id,
            action: 'send',
            signatureBase64,
        })
            .then((res) => {
                if (res.status === 200) {
                    navigate('/outbox');
                    notification.open({
                        type: 'success',
                        message: 'Успешно',
                        description: 'Документ успешно подписан и отправлен',
                    });
                }
            })
            .catch((err) => {
                notification.open({ type: 'error', message: 'Ошибка', description: err.message });
            });
    };

    return (
        <PageContainer>
            <div style={{ padding: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>Новый документ</h1>
                    <div style={{ marginTop: 18, display: 'flex', gap: 16 }}>
                        <Button type="default" onClick={() => navigate('/inbox')}>
                            Отмена
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            form="edit_form"
                            style={{ backgroundColor: '#E43D00', color: '#fff' }}
                            disabled={!submittable}
                        >
                            Сохранить
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#E43D00', color: '#fff' }}
                            onClick={() =>
                                handleSubmit(form.getFieldsValue(), handleSendDocument, (err) =>
                                    notification.open({ type: 'error', message: 'Ошибка', description: err.message }),
                                )
                            }
                            disabled={!(documentEditStore?.file && submittable)}
                            title={!documentEditStore?.file ? 'Необходимо заполнить обязательные поля' : undefined}
                        >
                            Отправить
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#E43D00', color: '#fff' }}
                            onClick={() =>
                                handleSubmit(
                                    form.getFieldsValue(),
                                    () => setVisible(true),
                                    (err) =>
                                        notification.open({
                                            type: 'error',
                                            message: 'Ошибка',
                                            description: err.message,
                                        }),
                                )
                            }
                            disabled={!(documentEditStore?.file && submittable)}
                            title={!documentEditStore?.file ? 'Необходимо заполнить обязательные поля' : undefined}
                        >
                            Подписать и отправить
                        </Button>
                    </div>
                </div>
                <h3>Общая информация</h3>
                <div>
                    {documentResponse && (
                        <DocumentForm
                            form={form}
                            onSave={handleSubmit}
                            setSubmittable={setSubmittable}
                            submittable={submittable}
                            formId="edit_form"
                            documentInfo={documentResponse?.document?.documentInfo}
                            id={documentResponse?.document?.id}
                            fileId={documentResponse.document?.file[0]?.id}
                        />
                    )}
                </div>
            </div>
            {visible && (
                <SignModal visible={visible} handleOk={handleSignDocument} handleCancel={() => setVisible(false)} />
            )}
        </PageContainer>
    );
};

export default observer(DocumentEdit);
