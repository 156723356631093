import { Button, Input, Table } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { PageContainer } from 'components/PageContainer';
import AccountsDrawer from 'pages/documents/common/accounts/AccountsDrawer';
import { IFeedType } from 'pages/feed/types';
import { feedStore } from 'pages/feed/feedStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ReactComponent as IconNoDocs } from 'assets/iconNoDocs.svg';
import { getColumns } from 'pages/feed/utils';

export const Feed = observer(() => {
    const [isAccountsOpen, setIsAccountsOpen] = React.useState(false);
    const { documents, isLoading, page, size } = feedStore;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        feedStore.getDocuments(location.pathname as IFeedType);
    }, [location.pathname, feedStore.query, page]);

    return (
        <PageContainer>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingRight: '16px',
                }}
            >
                <Input
                    style={{
                        borderRadius: '0',
                        height: '72px',
                        border: 'none',
                        paddingLeft: '24px',
                        boxShadow: 'none',
                    }}
                    placeholder="Найти"
                    value={feedStore.query}
                    onChange={(e) => feedStore.setQuery(e.target.value)}
                    prefix={<SearchOutlined />}
                />
                <Button
                    icon={<PlusOutlined />}
                    iconPosition="start"
                    style={{ backgroundColor: '#E43D00', color: '#fff' }}
                    onClick={() => setIsAccountsOpen(true)}
                >
                    Создать документ
                </Button>
            </div>
            <Table<any>
                pagination={{
                    position: ['bottomCenter'],
                    pageSize: size,
                    onChange: (val) => feedStore.setPage(val - 1),
                    total: documents?.totalElements,
                    current: feedStore.page + 1,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                }}
                size="large"
                loading={isLoading}
                columns={getColumns(location.pathname as IFeedType)}
                dataSource={documents?.content}
                rowKey={({ document }) => document.id}
                locale={{
                    emptyText: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: 'calc(100vh - 230px)',
                            }}
                        >
                            <IconNoDocs />
                            <span style={{ fontSize: '14px', fontWeight: '400', color: 'black' }}>
                                Документы отсутствуют
                            </span>
                        </div>
                    ),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => navigate(`/${location.pathname === '/draft' ? 'edit' : 'detail'}/${record.id}`),
                    };
                }}
            />
            {isAccountsOpen && <AccountsDrawer onClose={() => setIsAccountsOpen(false)} open={isAccountsOpen} />}
        </PageContainer>
    );
});
