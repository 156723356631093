import axios from 'axios';
import { keycloak } from '../keycloak';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${keycloak.token}`;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default axiosInstance;
