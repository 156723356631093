import { makeAutoObservable } from 'mobx';
import api from 'api';
import { IFeedResponse, IFeedType } from 'pages/feed/types';
import { notification } from 'antd';

export const feedStore = makeAutoObservable({
    documents: undefined as IFeedResponse | undefined,
    isLoading: false,
    query: '' as string,
    page: 0 as number,
    size: 10 as number,
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    },
    setQuery(query: string) {
        this.query = query;
        this.page = 0;
    },
    setPage(page: number) {
        this.page = page;
    },
    setSize(size: number) {
        this.size = size;
    },
    getDocuments(feed: IFeedType) {
        this.setIsLoading(true);
        let queryString = `?folderPath=${feed}`;

        if (this?.query) {
            queryString += `&search=${this.query}`;
        }

        if (this?.page || this?.page === 0) {
            queryString += `&page=${this.page}`;
        }

        if (this?.size) {
            queryString += `&size=${this.size}`;
        }
        api.get<IFeedResponse>('/api/v1/documents/search' + queryString)
            .then((response) => (this.documents = response.data))
            .catch((error) => notification.open({ type: 'error', message: 'Ошибка', description: error.message }))
            .finally(() => {
                this.setIsLoading(false);
            });
    },
    reset() {
        this.page = 0;
        this.size = 10;
        this.query = '';
    },
});
