export interface IDocumentResponse {
    id: string;
    document: {
        id: string;
        createdBy: ICreateBy;
        file: IFile[];
        documentInfo: IDocumentInfo;
        sendAt: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
    };
    endpoint: IEndpoint;
    folder: string;
    feedInfo: Record<string, any>;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface IDocumentInfo {
    documentNumber?: string;
    date?: string;
    documentType?: string;
    recipients?: IRecipient[];
    info?: {};
    status?: IDocumentStatuses | null;
    rejectComment?: string | null;
}

export interface IRecipient {
    inn: string;
    email?: string;
    endpointId?: string;
    accountName?: string;
}

export interface IFile {
    id: string;
    fileName: string;
    fileInfo: {
        fileType: 'attachment' | 'signature';
    };
    etag: string;
}

export interface ICreateBy {
    id: string;
    login: string;
    userInfo: { accountName: string };
}

export interface IEndpoint {
    id: string;
    defaultForAccount: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export const documentTypes = [
    { value: 'Акт выполненных заказов', label: 'Акт выполненных заказов' },
    { value: 'Акт выполненных работ', label: 'Акт выполненных работ' },
    { value: 'Договор', label: 'Договор' },
    { value: 'Акт сверки взаимозачетов', label: 'Акт сверки взаимозачетов' },
    { value: 'Отчеты', label: 'Отчеты' },
    { value: 'Переоценка', label: 'Переоценка' },
    { value: 'Ценовая спецификация', label: 'Ценовая спецификация' },
    { value: 'Дополнительное соглашение', label: 'Дополнительное соглашение' },
    { value: 'Другой документ', label: 'Другой документ' },
    { value: 'Доверенность', label: 'Доверенность' },
    { value: 'Счет', label: 'Счет' },
    { value: 'Возвратная накладная', label: 'Возвратная накладная' },
    { value: 'Приходная накладная', label: 'Приходная накладная' },
    { value: 'Акт переноса задолженности', label: 'Акт переноса задолженности' },
    { value: 'Акт ввода в эксплуатацию', label: 'Акт ввода в эксплуатацию' },
    { value: 'Акт приема-передачи', label: 'Акт приема-передачи' },
    { value: 'Дефектный акт', label: 'Дефектный акт' },
    { value: 'Протокол встречи', label: 'Протокол встречи' },
    { value: 'Маркетинговые соглашения', label: 'Маркетинговые соглашения' },
    { value: 'Договор поставки', label: 'Договор поставки' },
];

export type IDocumentStatuses = 'accepted' | 'denied' | 'pending';
