import React, { useEffect } from 'react';
import { Drawer, Input, Table, TableProps } from 'antd';
import { accountsDrawerStore } from './AccountsDrawerStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

interface IDrawerProps {
    onClose: () => void;
    open: boolean;
}

interface IRow {
    id: string;
    name: string;
    companyInfo: {
        inn: string;
        name: string;
    };
}

const columns: TableProps<IRow>['columns'] = [
    {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'ИНН получателя',
        dataIndex: ['companyInfo', 'inn'],
        key: 'companyInfo.inn',
    },
];

const App: React.FC<IDrawerProps> = ({ onClose, open }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        accountsDrawerStore.reset();
        onClose();
    };

    useEffect(() => {
        if (open) {
            accountsDrawerStore.init();
        }
    }, [open, accountsDrawerStore.query, accountsDrawerStore.size, accountsDrawerStore.page]);

    return (
        <Drawer size="large" title="Выберите получателя" onClose={handleClose} open={open}>
            <div>
                <Input
                    placeholder="Найти"
                    value={accountsDrawerStore.query}
                    onChange={(e) => accountsDrawerStore.setQuery(e.target.value)}
                    style={{
                        borderRadius: '0',
                        border: 'none',
                        paddingLeft: '0',
                        boxShadow: 'none',
                        marginTop: '-7px',
                        marginBottom: '10px',
                    }}
                    className=".input"
                    prefix={<SearchOutlined />}
                />
            </div>
            {accountsDrawerStore.accounts && (
                <Table<IRow>
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                navigate(`/create/?inn=${record.companyInfo.inn}&name=${record?.name}`, {
                                    replace: true,
                                });
                                onClose();
                            },
                        };
                    }}
                    pagination={{
                        position: ['bottomCenter'],
                        pageSize: accountsDrawerStore.size,
                        onChange: (val) => accountsDrawerStore.setPage(val - 1),
                        hideOnSinglePage: true,
                        total: accountsDrawerStore.accounts.totalElements,
                        current: accountsDrawerStore.page + 1,
                        showSizeChanger: false,
                    }}
                    scroll={{ y: 'calc(100vh - 200px)' }}
                    sticky
                    columns={columns}
                    rowKey={(company) => company.id}
                    dataSource={accountsDrawerStore.accounts.content}
                />
            )}
        </Drawer>
    );
};

export default observer(App);
