import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    notification,
    Row,
    Select,
    Tooltip,
    Upload,
    UploadFile,
} from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { documentTypes, IDocumentInfo, IFile } from 'pages/documents/types';
import { observer } from 'mobx-react';
import DebounceSelect from 'components/DebounceSelect';
import api from 'api';
import dayjs from 'dayjs';
import { DocumentViewer } from 'components/DocumentViewer';
import { documentEditStore } from 'pages/documents/edit/documentEditStore';
import { IFormValues } from 'pages/documents/edit';

interface IDocumentFormProps {
    documentInfo?: IDocumentInfo;
    files?: IFile[];
    onSave?: (formValues: IFormValues, successCallback?: () => void, errorCallback?: (err: any) => void) => void;
    formId: string;
    id?: string;
    setSubmittable: (value: boolean) => void;
    submittable: boolean;
    fileId?: string | null;
    form: FormInstance;
}

interface UserValue {
    label: string;
    value: string;
}

async function fetchUserList(username: string): Promise<UserValue[]> {
    const response = await api.get('/api/v1/accounts/search?name=' + username);

    return response.data.content.map(
        ({ companyInfo: { inn }, name }: { companyInfo: { inn: string }; name: string }) => ({
            label: `${name}`,
            value: inn,
        }),
    );
}

const DocumentForm = ({
    documentInfo,
    onSave,
    formId,
    id,
    setSubmittable,
    submittable,
    fileId,
    form,
}: IDocumentFormProps) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileIdSaved, setFileIdSaved] = useState(fileId);
    const formValues = Form.useWatch([], form);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [formValues, form]);

    useEffect(() => {
        if (fileIdSaved) {
            documentEditStore.setIsLoading(true);
            documentEditStore
                .loadFile(fileIdSaved)
                .then(() => {
                    setFileList([documentEditStore.file as any]);
                })
                .finally(() => {
                    documentEditStore.setIsLoading(false);
                });
        }
    }, [fileIdSaved, fileId]);

    const initialValues = documentInfo
        ? {
              ...documentInfo,
              recipients: documentInfo?.recipients?.map(({ inn, accountName }) => ({
                  label: accountName,
                  value: inn,
              })),
              date: dayjs(documentInfo?.date),
          }
        : {};

    const handleUpload = (file: any) => {
        const formData = new FormData();
        formData.append('file', file as any);
        documentEditStore.setIsLoading(true);
        api.post(`/api/v1/files?fileType=attachment&documentId=${id}`, formData)
            .then((res) => {
                setFileIdSaved(res.data.fileId);
                notification.open({
                    type: 'success',
                    message: 'Успешно',
                    description: 'Файл успешно сохранён',
                });
                documentEditStore.documentResponse?.id &&
                    documentEditStore.init(documentEditStore.documentResponse?.id);
            })
            .catch(() => {
                notification.open({
                    type: 'error',
                    message: 'Ошибка',
                    description: 'При сохранении файла произошла ошибка',
                });
            })
            .finally(() => {
                documentEditStore.setIsLoading(false);
            });
    };

    if (!documentInfo) {
        return null;
    }

    const handleSave = (values: IFormValues) => {
        onSave &&
            onSave(
                values,
                () =>
                    notification.open({
                        type: 'success',
                        message: 'Успешно',
                        description: 'Черновик успешно сохранён',
                    }),
                (err) => notification.open({ type: 'error', message: 'Ошибка', description: err.message }),
            );
    };

    const handleDelete = () => {
        api.delete<Blob>('/api/v1/files?fileId=' + fileIdSaved)
            .then(() => {
                setFileList([]);
                documentEditStore.setFile(null);
                notification.open({
                    type: 'success',
                    message: 'Успешно',
                    description: 'Файл успешно удалён',
                });
            })
            .catch(() =>
                notification.open({
                    type: 'error',
                    message: 'Ошибка при удалении',
                    description: 'При удалении файла произошла ошибка. Попробуйте позже',
                }),
            );
    };

    return (
        <Form form={form} name={formId} layout="vertical" initialValues={initialValues} onFinish={handleSave}>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        name="documentNumber"
                        label="Номер документа"
                        required
                        rules={[{ required: true, message: 'Поле обязательно к заполнению!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="documentType"
                        label="Тип документа"
                        required
                        rules={[{ required: true, message: 'Поле обязательно к заполнению!' }]}
                    >
                        <Select options={documentTypes} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="date"
                        label="Дата документа"
                        required
                        rules={[{ required: true, message: 'Поле обязательно к заполнению!' }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        required
                        rules={[{ required: true, message: 'Поле обязательно к заполнению!' }]}
                        name="recipients"
                        label="Получатель"
                    >
                        <DebounceSelect
                            mode="multiple"
                            maxCount={1}
                            placeholder="Введите имя получателя"
                            fetchOptions={fetchUserList}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="files" label="Файл" required={!!id}>
                        {!fileList[0] && (
                            <Upload
                                beforeUpload={(file) => {
                                    setFileList([file]);
                                    handleUpload(file);
                                }}
                                // customRequest={handleUpload}
                                accept=".doc,.docx,.pdf,.xls,.xlsx"
                                fileList={fileList}
                                disabled={!submittable}
                                showUploadList={false}
                            >
                                {!id && (
                                    <Tooltip title="Сначала сохраните черновик">
                                        <Button disabled={true} icon={<UploadOutlined />}>
                                            Загрузить файл
                                        </Button>
                                    </Tooltip>
                                )}
                                {id && !fileList[0] && (
                                    <Button
                                        disabled={!submittable || documentEditStore.isLoading}
                                        icon={<UploadOutlined />}
                                    >
                                        Загрузить файл
                                    </Button>
                                )}
                            </Upload>
                        )}
                        {id && fileList[0] && (
                            <Button
                                style={{ backgroundColor: '#E43D00', color: '#fff' }}
                                type="primary"
                                disabled={documentEditStore.isLoading}
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete()}
                            >
                                Удалить файл
                            </Button>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <DocumentViewer loading={documentEditStore.isLoading} file={fileList[0] as any} />
        </Form>
    );
};

export default observer(DocumentForm);
