import { documentCreateButtonContainer, logoContainer, siderStyle } from 'style/appStyles';
import { Button, Layout, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getLeftMenuItems } from 'utils';
import { ReactComponent as LogoSmall } from 'assets/logoSmall.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { feedStore } from 'pages/feed/feedStore';

const { Sider } = Layout;

interface ILeftMenuProps {
    collapsed: boolean;
    setCollapsed: (value: boolean) => void;
}

export const LeftMenu = ({ collapsed, setCollapsed }: ILeftMenuProps) => {
    const navigate = useNavigate();

    return (
        <Sider trigger={null} collapsed={collapsed} collapsible style={siderStyle}>
            <div style={logoContainer}>{collapsed ? <LogoSmall /> : <Logo />}</div>
            <div className="menu">
                <Menu
                    onClick={(value) => {
                        feedStore.reset();
                        navigate(value.key);
                    }}
                    theme="dark"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    items={getLeftMenuItems()}
                />
            </div>
            <div style={documentCreateButtonContainer}>
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '14px',
                        fontWeight: '700',
                        color: '#adb0b3',
                    }}
                >
                    {!collapsed && 'Свернуть меню'}
                </Button>
            </div>
        </Sider>
    );
};
