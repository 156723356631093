import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState } from 'react';
import { Spin } from 'antd';

export const DocumentViewer = ({ file, loading, label }: { file: File | null; loading?: boolean; label?: string }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    const [pagesCount, setPagesCount] = useState<number>();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setPagesCount(numPages);
    }

    const getPages = () => {
        const result = [];

        if (pagesCount) {
            for (let i = 1; i <= pagesCount; i++) {
                result.push(i);
            }
        }

        return result.map((item) => (
            <Page
                scale={1.5}
                className="page-viewer"
                renderAnnotationLayer={false}
                renderTextLayer={false}
                key={`page-${item}`}
                pageNumber={item}
            />
        ));
    };

    return (
        <div className="pages-container">
            {loading && (
                <div className="spinner-container">
                    <Spin size="large" />
                </div>
            )}
            {!loading && file && (
                <>
                    {label && <p className="pdf-options-name">{label}</p>}
                    <Document className="pdf-viewer" onLoadSuccess={onDocumentLoadSuccess} file={file}>
                        {getPages()}
                    </Document>
                </>
            )}
        </div>
    );
};
