import React, { useState } from 'react';
import { Layout } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Feed } from 'pages/feed/Feed';
import './App.scss';
import { useKeycloak } from '@react-keycloak/web';
import { HeaderComponent } from 'components/HeaderComponent';
import { LeftMenu } from 'components/LeftMenu';
import DocumentDetail from './pages/documents/detail/DocumentDetail';
import DocumentEdit from './pages/documents/edit/DocumentEdit';
import DocumentCreate from './pages/documents/create/DocumentCreate';

const { Content } = Layout;

const App: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { initialized } = useKeycloak();

    if (!initialized) {
        return null;
    }

    return (
        <Layout style={{ fontFamily: 'inherit', background: 'white' }} hasSider>
            <LeftMenu collapsed={collapsed} setCollapsed={setCollapsed} />
            <Layout style={{ marginInlineStart: collapsed ? 80 : 200 }}>
                <HeaderComponent />
                <Content>
                    <Routes>
                        <Route path="/" element={<Navigate to="/inbox" />} />
                        <Route path="/inbox" element={<Feed />} />
                        <Route path="/outbox" element={<Feed />} />
                        <Route path="/draft" element={<Feed />} />
                        <Route path="/detail/:id" element={<DocumentDetail />} />
                        <Route path="/create" element={<DocumentCreate />} />
                        <Route path="/edit/:draftId" element={<DocumentEdit />} />
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;
