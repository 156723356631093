import React, { useEffect } from 'react';
import { Input, Form, Row, Col, Button, notification } from 'antd';
import { documentDetailsStore } from './documentDetailStore';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { parseISO, format } from 'date-fns';
import { PageContainer } from 'components/PageContainer';
import { DocumentViewer } from 'components/DocumentViewer';
import api from 'api';
import { getStatus } from 'pages/feed/utils';
import SignModal, { signDocument } from 'components/SignModal';
import RejectModal from 'components/RejectModal';
import { Certificate } from 'crypto-pro';
import { IFeedType } from 'pages/feed/types';
import { performPrintPDF } from 'pages/documents/performPrintPDF';

const DocumentDetail = () => {
    const navigate = useNavigate();
    const [modelAction, setModalAction] = React.useState<'accept' | 'deny' | null>(null);
    const [rejectModalMode, setRejectModalMode] = React.useState<'denySign' | 'deny' | null>(null);
    const [rejectComment, setRejectComment] = React.useState<string | null>(null);
    const { id } = useParams<{ id: string }>();
    const { documentResponse } = documentDetailsStore;
    const formattedDate = documentResponse?.document?.documentInfo?.date
        ? format(parseISO(documentResponse?.document?.documentInfo?.date), 'dd.MM.yyyy')
        : '';
    const isActionsAvailable =
        documentResponse?.document?.documentInfo?.status === 'pending' && documentResponse?.folder === '/inbox';
    const signFiles = documentResponse?.document?.file?.filter((file) => file?.fileInfo?.fileType === 'signature');
    const isSignNeeded = signFiles ? signFiles.length > 0 : false;
    const rejectCommentText = documentResponse?.document?.documentInfo?.rejectComment;

    useEffect(() => {
        id && documentDetailsStore.init(id);

        return () => documentDetailsStore.reset();
    }, [id]);

    useEffect(() => {
        if (documentResponse?.document?.file) {
            const fileId = documentResponse?.document?.file?.[0]?.id;

            if (fileId) {
                documentDetailsStore.loadPrintForm(documentResponse.id);
            }
        }
    }, [documentResponse]);

    const handleRejectModal = (comment: string) => {
        setRejectComment(comment);
        rejectModalMode === 'denySign' ? setModalAction('deny') : handleAction('deny', null, comment);
        setRejectModalMode(null);
    };

    const handleSignDocument = async (certificate: Certificate | null) => {
        if (!certificate) {
            return;
        }
        let signatureBase64 = '';

        const fileResponse = await api.get<Blob>('/api/v1/files?fileId=' + documentResponse?.document?.file?.[0]?.id, {
            responseType: 'blob',
        });

        if (fileResponse.status !== 200) {
            notification.open({ type: 'error', message: 'Ошибка', description: 'Не удалось загрузить файл' });

            return;
        }

        try {
            signatureBase64 = await signDocument(fileResponse.data, certificate);
        } catch (err: any) {
            console.error(err);
            notification.open({ type: 'error', message: 'Ошибка', description: err.message });

            return;
        }

        modelAction && handleAction(modelAction, signatureBase64, rejectComment);
    };

    const handleAction = (action: string, signatureBase64?: string | null, comment?: string | null) => {
        api.post('/api/v1/documents/actions', {
            documentFeedId: documentResponse?.id,
            action,
            signatureBase64: signatureBase64 || null,
            rejectComment: comment || null,
        })
            .then((res) => {
                if (res.status === 200) {
                    if (documentResponse?.folder === '/outbox') {
                        navigate('/outbox');
                    } else {
                        navigate('/inbox');
                    }
                    notification.open({
                        type: 'success',
                        message: 'Успешно',
                        description: action === 'accept' ? 'Документ успешно принят' : 'Документ успешно отклонён',
                    });
                }
            })
            .catch((err) => {
                notification.open({ type: 'error', message: 'Ошибка', description: err.message });
            });
    };

    return (
        <PageContainer>
            <div style={{ padding: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '15px',
                        }}
                    >
                        <h1>
                            Документ №{documentResponse?.document?.documentInfo?.documentNumber} от {formattedDate}
                        </h1>
                        {getStatus(
                            documentResponse?.document.documentInfo.status,
                            documentResponse?.folder as IFeedType,
                        )}
                    </div>
                    <div style={{ marginTop: 18, display: 'flex', gap: 16 }}>
                        <Button
                            type="default"
                            onClick={() => {
                                if (documentDetailsStore.file) {
                                    const blob = new Blob([documentDetailsStore.file], { type: 'application/pdf' });
                                    performPrintPDF(blob);
                                }
                            }}
                        >
                            Печать
                        </Button>
                        {isActionsAvailable &&
                            (isSignNeeded ? (
                                <>
                                    <Button type="default" onClick={() => setRejectModalMode('denySign')}>
                                        Подписать и Отклонить
                                    </Button>
                                    <Button type="primary" onClick={() => setModalAction('accept')}>
                                        Подписать и Принять
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button type="default" onClick={() => setRejectModalMode('deny')}>
                                        Отклонить
                                    </Button>
                                    <Button type="primary" onClick={() => handleAction('accept')}>
                                        Принять
                                    </Button>
                                </>
                            ))}
                    </div>
                </div>
                {rejectCommentText && (
                    <div
                        style={{
                            border: '1px solid #E43D00',
                            display: 'flex',
                            padding: 16,
                            marginBottom: 16,
                            borderRadius: 8,
                        }}
                    >
                        {documentResponse?.document?.documentInfo?.rejectComment}
                    </div>
                )}
                <div>
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Номер документа">
                                    <Input disabled value={documentResponse?.document?.documentInfo?.documentNumber} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Тип документа">
                                    <Input disabled value={documentResponse?.document?.documentInfo?.documentType} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Дата документа">
                                    <Input disabled value={formattedDate} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                {documentResponse?.folder === '/inbox' ? (
                                    <Form.Item label="Отправитель">
                                        <Input
                                            disabled
                                            value={documentResponse?.document.createdBy.userInfo.accountName}
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item label="Получатель">
                                        <Input
                                            disabled
                                            value={
                                                documentResponse?.document?.documentInfo?.recipients?.[0]?.accountName
                                            }
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </div>
                <DocumentViewer loading={documentDetailsStore.isLoading} file={documentDetailsStore.file} />
            </div>
            {!!modelAction && (
                <SignModal
                    visible={!!modelAction}
                    handleOk={handleSignDocument}
                    handleCancel={() => setModalAction(null)}
                />
            )}
            {!!rejectModalMode && (
                <RejectModal
                    visible={!!rejectModalMode}
                    handleOk={handleRejectModal}
                    handleCancel={() => setRejectModalMode(null)}
                />
            )}
        </PageContainer>
    );
};

export default observer(DocumentDetail);
