import { makeAutoObservable } from 'mobx';
import api from 'api';
import { IDocumentResponse } from '../types';

export const documentDetailsStore = makeAutoObservable({
    isLoading: false,
    documentResponse: null as IDocumentResponse | null,
    file: null as File | null,
    setDocumentResponse(documentResponse: IDocumentResponse | null) {
        this.documentResponse = documentResponse;
    },
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    },
    async init(id: string) {
        this.setIsLoading(true);
        const response = await api.get<IDocumentResponse>('/api/v1/documents?documentFeedId=' + id);
        this.setIsLoading(false);

        if (response.status === 200) {
            this.setDocumentResponse(response.data);
        }
    },
    async loadFile(id: string) {
        this.setIsLoading(true);
        const response = await api.get<Blob>('/api/v1/files?fileId=' + id + '&convert=true', { responseType: 'blob' });
        this.setIsLoading(false);

        if (response.status === 200) {
            this.file = new File([response.data], 'name');
        }
    },
    async loadPrintForm(id: string) {
        this.setIsLoading(true);
        const response = await api.get<Blob>('/api/v1/documents/print?documentFeedId=' + id, { responseType: 'blob' });
        this.setIsLoading(false);

        if (response.status === 200) {
            this.file = new File([response.data], 'name');
        }
    },
    reset() {
        this.setDocumentResponse(null);
        this.setIsLoading(false);
    },
});
