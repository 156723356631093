import React, { useState } from 'react';
import { Button, Form, notification } from 'antd';
import { pdfjs } from 'react-pdf';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageContainer } from 'components/PageContainer';
import DocumentForm from '../common/form/DocumentForm';
import { IDocumentInfo } from '../types';
import api from 'api';
import { formatISO } from 'date-fns';
import { IFormValues } from 'pages/documents/edit';

const DocumentCreate = () => {
    const [submittable, setSubmittable] = useState<boolean>(false);
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [documentFeedId, setDocumentFeedId] = useState<string | null>(null);
    const inn = searchParams.get('inn');
    const name = searchParams.get('name');
    const [form] = Form.useForm();

    const handleSave = (values: IFormValues) => {
        const recipients = values.recipients.map(({ value }) => ({
            inn: value,
        }));
        const result: IDocumentInfo = {
            documentNumber: values.documentNumber,
            documentType: values.documentType,
            date: formatISO(values.date),
            recipients,
        };
        api.post<{ documentId: string; draftFeedId: string }>('/api/v1/documents', result)
            .then((response) => {
                if (response.status === 200) {
                    setDocumentFeedId(response.data?.documentId);
                    notification.open({
                        type: 'success',
                        message: 'Успешно',
                        description: 'Черновик успешно сохранён',
                    });
                    navigate(`/edit/${response.data.draftFeedId}`);
                }
            })
            .catch((err) => notification.open({ type: 'error', message: 'Ошибка', description: err.message }));
    };

    return (
        <PageContainer>
            <div style={{ padding: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>Новый документ</h1>
                    <div style={{ marginTop: 18, display: 'flex', gap: 16 }}>
                        <Button type="default" onClick={() => navigate('/draft')}>
                            {documentFeedId ? 'Закрыть' : 'Отмена'}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            form="create_form"
                            style={{ backgroundColor: '#E43D00', color: '#fff' }}
                            disabled={!submittable}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>
                <h3>Общая информация</h3>
                <div>
                    <DocumentForm
                        form={form}
                        setSubmittable={setSubmittable}
                        submittable={submittable}
                        documentInfo={{ recipients: [{ inn: inn || '', accountName: name || '' }] }}
                        onSave={handleSave}
                        formId="create_form"
                    />
                </div>
            </div>
        </PageContainer>
    );
};

export default observer(DocumentCreate);
