import React from 'react';
import { ReactComponent as IconInbox } from './assets/iconInbox.svg';
import { ReactComponent as IconInboxActive } from './assets/iconInboxActive.svg';
import { ReactComponent as IconOutbox } from './assets/iconOutbox.svg';
import { ReactComponent as IconOutboxActive } from './assets/iconOutboxActive.svg';
import { ReactComponent as IconDraft } from './assets/iconDraft.svg';
import { ReactComponent as IconDraftActive } from './assets/iconDraftActive.svg';
import { useLocation } from 'react-router-dom';

export const getLeftMenuItems = () => {
    const location = useLocation();

    return [
        {
            key: '/inbox',
            label: 'Входящие',
            title: 'Входящие',
            icon: location.pathname === '/inbox' ? <IconInboxActive /> : <IconInbox />,
        },
        {
            key: '/outbox',
            label: 'Исходящие',
            title: 'Исходящие',
            icon: location.pathname === '/outbox' ? <IconOutboxActive /> : <IconOutbox />,
        },
        {
            key: '/draft',
            label: 'Черновики',
            title: 'Черновики',
            icon: location.pathname === '/draft' ? <IconDraftActive /> : <IconDraft />,
        },
    ];
};
