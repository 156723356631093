import { notification } from 'antd';

const createBlobAndPrint = (data, iframe) => {
    let localPdf = data;
    localPdf = URL.createObjectURL(localPdf);

    iframe.setAttribute('src', localPdf);
};

const getFile = async (data, iframe) => {
    await createBlobAndPrint(data, iframe);
};

export const performPrintPDF = (data) => {
    let iframe = window._printIframe;

    if (!window._printIframe) {
        iframe = window._printIframe = document.createElement('iframe');

        iframe.style.height = '0';
        iframe.style.width = '0';
        iframe.style.visibility = 'hidden';
        iframe.style.border = 'none';
        iframe.style.position = 'absolute';
        iframe.style.left = '-9000px';

        document.body.appendChild(iframe);

        let timeout = null;

        iframe.onload = () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(function () {
                iframe.focus();

                if (!iframe.getAttribute('src')) {
                    return;
                }

                try {
                    iframe.contentWindow.print();
                } catch (e) {
                    notification.open({
                        type: 'error',
                        message: 'Ошибка',
                        description: 'Отключены диалоговые окна. Перезагрузите страницу',
                    });
                }
            }, 1000);
        };
    }

    getFile(data, iframe).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
    });
};
