import { Modal, Select, Input } from 'antd';
import { useEffect, useState } from 'react';

interface RejectModalProps {
    visible: boolean;
    handleOk: (rejectComment: string) => void;
    handleCancel: () => void;
}

const RejectModal = ({ visible, handleOk, handleCancel }: RejectModalProps) => {
    const [rejectComment, setRejectComment] = useState<string>('');

    return (
        <Modal
            title="Укажите причину отклонения документ"
            open={visible}
            onOk={() => handleOk(rejectComment)}
            okButtonProps={{ disabled: !rejectComment }}
            onCancel={handleCancel}
            cancelText="Отменить"
            okText="Отклонить"
        >
            <div style={{ paddingBottom: 24 }}>
                <p>Комментарий</p>
                <Input size="large" onChange={(v) => setRejectComment(v.target.value)} value={rejectComment} />
            </div>
        </Modal>
    );
};

export default RejectModal;
