import { Dropdown, Layout, type MenuProps, Space } from 'antd';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { NavLink, useLocation } from 'react-router-dom';
import { getLeftMenuItems } from 'utils';
import { documentDetailsStore } from 'pages/documents/detail/documentDetailStore';
import { observer } from 'mobx-react';

const { Header } = Layout;

export const HeaderComponent = observer(() => {
    const { keycloak } = useKeycloak();
    const location = useLocation();

    const label = getLeftMenuItems().find((item) => item.key === location.pathname)?.label;

    const document = documentDetailsStore.documentResponse;

    const dropdownItems: MenuProps['items'] = [
        {
            label: 'Выйти',
            icon: <LogoutOutlined />,
            key: 'logout',
        },
    ];

    const getBreadCrumbs = () => {
        if (location.pathname.includes('/create')) {
            return (
                <div>
                    <NavLink style={{ color: 'rgba(0, 0, 0, 0.55)' }} to="/draft">
                        Черновики
                    </NavLink>
                    <span> / Cоздание документа</span>
                </div>
            );
        }

        if (location.pathname.includes('/edit')) {
            return (
                <div>
                    <NavLink style={{ color: 'rgba(0, 0, 0, 0.55)' }} to="/draft">
                        Черновики
                    </NavLink>
                    <span> / Редактирование документа</span>
                </div>
            );
        }

        if (location.pathname.includes('/detail')) {
            return (
                <div>
                    <NavLink style={{ color: 'rgba(0, 0, 0, 0.55)' }} to={document?.folder || 'inbox'}>
                        {getLeftMenuItems().find((item) => item.key === document?.folder)?.label}
                    </NavLink>
                    <span> / Документ</span>
                </div>
            );
        }

        return <span style={{ fontWeight: 500, fontSize: '20px' }}>{label}</span>;
    };

    const onDropdownItemClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'logout') {
            keycloak.logout();
        }
    };

    return (
        <Header
            style={{
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 24px',
                borderBottom: '1px solid #f0f0f0',
            }}
        >
            {getBreadCrumbs()}
            <Dropdown
                overlayStyle={{ top: '50px' }}
                menu={{ items: dropdownItems, onClick: onDropdownItemClick }}
                trigger={['click']}
            >
                <a style={{ color: 'black' }} onClick={(e) => e.preventDefault()}>
                    <Space>
                        {(keycloak && keycloak.tokenParsed?.name) || keycloak.tokenParsed?.email}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Header>
    );
});
