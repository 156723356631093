import { makeAutoObservable } from 'mobx';
import { IDocumentResponse } from 'pages/documents/types';
import api from 'api';

export const documentEditStore = makeAutoObservable({
    isLoading: false,
    documentResponse: null as IDocumentResponse | null,
    file: null as Blob | null,
    setFile(file: Blob | File | null) {
        this.file = file;
    },
    setDocumentResponse(documentResponse: IDocumentResponse | null) {
        this.documentResponse = documentResponse;
    },
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    },
    async init(id: string) {
        this.setIsLoading(true);
        const response = await api.get<IDocumentResponse>('/api/v1/documents?documentFeedId=' + id);
        this.setIsLoading(false);

        if (response.status === 200) {
            this.setDocumentResponse(response.data);
        }
    },
    async loadFile(id: string) {
        this.setIsLoading(true);
        const response = await api.get<Blob>('/api/v1/files?fileId=' + id + '&convert=true', { responseType: 'blob' });
        this.setIsLoading(false);

        if (response.status === 200) {
            this.file = response.data;
        }
    },
    reset() {
        this.setDocumentResponse(null);
        this.setIsLoading(false);
        this.file = null;
    },
});
