import { TableProps, Tag } from 'antd';
import { format, parseISO } from 'date-fns';
import { IFeedType } from 'pages/feed/types';
import React from 'react';
import { IDocumentStatuses } from 'pages/documents/types';

export const getColumns = (location: IFeedType) => {
    const defaultColumns: TableProps<any>['columns'] = [
        {
            title: '№ документа',
            dataIndex: ['document', 'documentInfo', 'documentNumber'],
            key: 'document.documentInfo.documentNumber',
        },
        {
            title: 'Получатель',
            dataIndex: ['document', 'documentInfo', 'recipients', '0', 'accountName'],
            key: 'recipients',
        },
        {
            title: 'Тип документа',
            dataIndex: ['document', 'documentInfo', 'documentType'],
            key: 'docType',
        },
        {
            title: 'Дата документа',
            key: 'docDate',
            render: (_, { document }) =>
                document?.documentInfo?.date && format(parseISO(document?.documentInfo?.date), 'dd.MM.yyyy'),
        },
        {
            title: 'Дата поступления',
            key: 'sendAt',
            render: (_, { document }) => document?.sendAt && format(parseISO(document?.sendAt), 'dd.MM.yyyy'),
        },
        {
            title: 'Статус',
            key: 'status',
            render: (_, { feedInfo }) => getStatus(feedInfo?.status, location),
        },
    ];

    if (location === '/inbox') {
        defaultColumns[1] = {
            title: 'Отправитель',
            dataIndex: ['document', 'createdBy', 'userInfo', 'accountName'],
            key: 'recipients',
        };
    }

    if (location === '/draft') {
        const array: TableProps<any>['columns'] = defaultColumns.slice(0, 4);

        array.push({
            title: 'Дата изменения',
            dataIndex: ['updatedAt'],
            key: 'updatedAt',
            render: (_, { updatedAt }) => updatedAt && format(parseISO(updatedAt), 'dd.MM.yyyy'),
        });

        return array;
    }

    return defaultColumns;
};

export const getStatus = (status?: IDocumentStatuses | null, location?: IFeedType) => {
    let color = '';
    let text = '';

    if (!status) {
        return null;
    }

    if (status === 'accepted') {
        color = 'green';
        text = 'принят';
    }

    if (status === 'denied') {
        color = 'volcano';
        text = 'отклонён';
    }

    if (status === 'pending') {
        if (location === '/outbox') {
            text = 'ожидается ответ контрагента';
            color = 'geekblue';
        } else {
            text = 'требует действия';
            color = 'geekblue';
        }
    }

    return (
        <>
            <Tag color={color} key={status}>
                {text?.toUpperCase()}
            </Tag>
        </>
    );
};
