import { makeAutoObservable } from 'mobx';
import { IAccountsResponse } from './accountsDrawer.types';
import api from 'api';

export const accountsDrawerStore = makeAutoObservable({
    isLoading: false,
    query: '',
    accounts: null as IAccountsResponse | null,
    page: 0,
    size: 10 as number,
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    },
    setQuery(query: string) {
        this.query = query;
        this.page = 0;
    },
    setPage(page: number) {
        this.page = page;
    },
    setSize(size: number) {
        this.size = size;
    },
    async init() {
        this.setIsLoading(true);
        const response = await api.get<IAccountsResponse>(
            `/api/v1/accounts/search?query=${this.query}&page=${this.page}&size=${this.size}`,
        );
        this.setIsLoading(false);

        if (response.status === 200) {
            this.accounts = response.data;
        }
    },
    reset() {
        this.accounts = null;
        this.page = 0;
        this.query = '';
        this.size = 10;
        this.setIsLoading(false);
    },
});
