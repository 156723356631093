import { Modal, Select } from 'antd';
import { Certificate, createDetachedSignature, createHash, getUserCertificates } from 'crypto-pro';
import { useEffect, useState } from 'react';

interface SignModalProps {
    visible: boolean;
    handleOk: (certificate: Certificate | null) => void;
    handleCancel: () => void;
}

export const signDocument = async (document: Blob, certificate: Certificate) => {
    const arrayBuffer = await document.arrayBuffer();
    const hash = await createHash(arrayBuffer, {
        encoding: 'utf8',
    });

    return await createDetachedSignature(certificate.thumbprint, hash);
};

const SignModal = ({ visible, handleOk, handleCancel }: SignModalProps) => {
    const [certificates, setCertificates] = useState<Certificate[]>([]);
    const [selectedCertificate, setSelectedCertificate] = useState<Certificate | null>(null);
    useEffect(() => {
        getUserCertificates().then((certs) => {
            setCertificates(certs);
        });
    }, []);

    return (
        <Modal
            title="Выбор сертификата"
            open={visible}
            onOk={() => handleOk(selectedCertificate)}
            okButtonProps={{ disabled: !selectedCertificate }}
            onCancel={handleCancel}
            cancelText="Отменить"
            okText="Подписать"
        >
            <div style={{ paddingBottom: 24 }}>
                <p>Укажите сертификат, который будет использован для подписи документа.</p>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Выберите сертификат"
                    value={selectedCertificate?.thumbprint}
                    onChange={(value) => {
                        const cert = certificates.find((c) => c.thumbprint === value);

                        if (cert) {
                            setSelectedCertificate(cert);
                        }
                    }}
                >
                    {certificates.map((cert) => (
                        <Select.Option key={cert.thumbprint} value={cert.thumbprint}>
                            {cert.subjectName}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </Modal>
    );
};

export default SignModal;
