import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from 'keycloak';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }}>
        <BrowserRouter>
            <ConfigProvider
                theme={{
                    components: {
                        Pagination: {
                            colorPrimary: '#e43d00',
                            colorPrimaryHover: '#e43d00',
                        },
                    },
                }}
            >
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </ReactKeycloakProvider>,
);
