import Keycloak from 'keycloak-js';

const CONFIG = window.KEYCLOAK_CONFIG || {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: 'omnidoc',
    clientId: 'omnidoc-cli',
    onLoad: 'login-required',
};

export const keycloak = new Keycloak(CONFIG);
